/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { Box, LinearProgress } from "@mui/material";
import { motion } from "framer-motion";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import "../index.css";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
// require("dotenv").config()

const MyForm = () => {
  const [step, setStep] = useState<number>(0);
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const submitEmail = async (email: string) => {
    setLoading(true);
    try {
      await axios.post(
        // "http://localhost:8000/api/v1/waitlist",
        `https://roboticsir.in/call/api/v1/waitlist`,
        { email }
      );
      // If the email is successfully submitted, move to the next step
      setLoading(false);
      nextStep();
      toast("Check E-Mail!", {
        icon: "😉",
      });
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        if (error.response.status === 409) {
          // Email already exists
          toast("Email already exists!", {
            icon: "🦆",
          });
        } else if (error.response.status === 400) {
          // Server error or email sending issue
          toast.error("Error sending mail. Please try again.");
        }
      } else {
        // Generic error
        toast.error("Something went wrong. Please try again.");
      }
    }
  };

  const nextStep = () => setStep((prevStep) => prevStep + 1);
  return (
    <>
      <Toaster />
      {/* Progress Bar */}
      <Box className="w-full mb-6">
        <LinearProgress variant="determinate" value={step * 50} />
      </Box>

      {/* Multi-step Form */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full p-5 gap-0 -mt-7 myfont text-center flex flex-col justify-center items-center h-full"
      >
        {step === 0 && <Step1 nextStep={nextStep} />}
        {step === 1 && (
          <Step2
            nextStep={nextStep}
            setEmail={setEmail}
            submitEmail={submitEmail}
            loading={loading}
          />
        )}
        {step === 2 && <Step3 email={email} />}
      </motion.div>
    </>
  );
};

export default MyForm;
