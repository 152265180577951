import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import BG from "./assets/gear.svg";
import ErrorBoundary from "./components/ErrorBoundary";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <div className="relative w-full h-full">
        <img src={BG} alt="" className="gear" />
        {/* <div className="fixed w-full h-full bg "></div> */}
        <App />
      </div>
    </ErrorBoundary>
  </React.StrictMode>
);
