import { Button, Typography } from "@mui/material";
import { motion } from "framer-motion";
import "../index.css";

interface Step1Props {
  nextStep: () => void;
}

const Step1: React.FC<Step1Props> = ({ nextStep }) => {
  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "-100%" }}
      transition={{ duration: 0.5 }}
      className="text-center myfont"
    >
      <Typography variant="h5" className="myfont" gutterBottom>
        <span className="myfont">Get Early Access and Insider Updates</span>
      </Typography>
      <br />
      <Button variant="contained" color="primary" onClick={nextStep}>
        <span className="myfont">Join Waitlist</span>
      </Button>
    </motion.div>
  );
};

export default Step1;
