import { Button, TextField, Typography, CircularProgress } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { motion } from "framer-motion";

interface Step2Props {
  nextStep: () => void;
  setEmail: (email: string) => void;
  submitEmail: (email: string) => void;
  loading: boolean;
}

const Step2: React.FC<Step2Props> = ({ submitEmail, setEmail, loading }) => {
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
  });

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "-100%" }}
      transition={{ duration: 0.5 }}
      className="text-center"
    >
      <Typography variant="h5" gutterBottom>
        <span className="myfont">Enter your Email</span>
      </Typography>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setEmail(values.email);
          submitEmail(values.email);
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <Field
              as={TextField}
              name="email"
              label="Email"
              fullWidth
              variant="outlined"
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              margin="normal"
            />
            
            <br />
            <br />
            {loading ? (
                <CircularProgress color="primary" />
              ) : (<Button type="submit" variant="contained" color="primary">
              <span className="myfont ">Submit</span>
            </Button>)}
          </Form>
        )}
      </Formik>
    </motion.div>
  );
};

export default Step2;
