import { Typography } from "@mui/material";
import { motion } from "framer-motion";
import "../index.css";

interface Step3Props {
  email: string;
}

const Step3: React.FC<Step3Props> = ({ email }) => {
  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "-100%" }}
      transition={{ duration: 0.5 }}
      className="text-center"
    >
      <Typography variant="h5" className="myfont" gutterBottom>
        <span className="myfont ">Thank You!</span>
      </Typography>
      <Typography variant="body1">
        <span className="myfont ">
          Thank you for joining the waitlist. <br />
          We have received your email:<u> {email}</u>
        </span>
      </Typography>
    </motion.div>
  );
};

export default Step3;
