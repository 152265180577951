import Header from "./assets/header.svg";
import MyForm from "./components/myForm";
import { useEffect, useState } from "react";
import "./index.css";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { HiEnvelope } from "react-icons/hi2";
import { HiMiniPhone } from "react-icons/hi2";
import { toast, Toaster } from "react-hot-toast";
import { createTheme, ThemeProvider } from "@mui/material";

function App() {
  const [hovered, setHovered] = useState(false);
  const [mail, showMail] = useState(false);
  const [phone, showPhone] = useState(false);

  const actions = [
    {
      icon: <HiEnvelope />,
      name: "Mail us",
      onClick: () => showMail(true),
    },
    {
      icon: <HiMiniPhone />,
      name: "Call us",
      onClick: () => showPhone(true),
    },
  ];

  useEffect(() => {
    if (mail) {
      // console.log("mail toast triggered");
      toast("Mail us at: ai@roboticsir.com", {
        icon: "📧",
        duration: 5000,
      });

      // Reset mail state after toast duration
      const timer = setTimeout(() => {
        showMail(false);
      }, 5000); // Match the duration of the toast

      return () => clearTimeout(timer);
    }
  }, [mail]);

  useEffect(() => {
    if (phone) {
      // console.log("phone toast triggered");
      toast("Call us at: +91 9267913431", {
        icon: "📞",
        duration: 5000,
      });

      // Reset phone state after toast duration
      const timer = setTimeout(() => {
        showPhone(false);
      }, 5000); // Match the duration of the toast

      return () => clearTimeout(timer);
    }
  }, [phone]);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#ff9800", // Orange color
      },
    },
  });

  if (process.env.NODE_ENV === "production") {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
    console.warn = () => {};
  }
  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="md:w-fit w-full flex flex-row justify-center items-center">
          <Toaster />
          <h1
            className="md:text-[3vw] text-[8vw] sm:text-[5vw] logo-font md:mt-1 md:ml-3"
            onMouseOver={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <span className="text-[#FF6600]">R</span>obotic <span>S</span>ir
            <div
              className={
                hovered
                  ? "h-1 bg-[#FF6600] w-full -mt-3 z-10 transition-all duration-500 cursor-pointer"
                  : "w-0 bg-[#FF6600] h-1 -mt-3 z-10 transition-all duration-500 cursor-pointer"
              }
            ></div>
          </h1>
        </div>
        <br />
        <br />
        <div className="w-full flex flex-col justify-center items-center">
          <div className="flex flex-col ">
            <img src={Header} className="header" alt="header" />
          </div>
          <br />
          <br />
          <br />
          <div className="md:w-[50vw] w-[80vw] h-[40vh] myfont bg-[#FFE8D9] border-2 border-orange-500 rounded-lg overflow-hidden z-10">
            <MyForm />
          </div>
        </div>
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{
            position: "fixed",
            bottom: 25,
            left: 25,
          }}
          icon={<SpeedDialIcon />}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.onClick} // Directly use onClick from actions
            />
          ))}
        </SpeedDial>
      </ThemeProvider>
    </>
  );
}

export default App;
